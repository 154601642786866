import { useState, useEffect, useRef } from "react";
import { GET_TABLE_BOOK_DETAIL } from '../../helpers/url_helper';
import { post } from '../../helpers/api_helper';
import { img_view } from '../../helpers/Helper';
import { toast } from 'react-toastify';
import { Link, useHistory } from "react-router-dom";

import UserNameHeader from '../../component/UserNameHeader';
import UserMenu from '../../component/UserMenu';

export default function Home(props) {
    let history = useHistory();
    const [BookingDetail, setBookingDetail] = useState({});
    const [isLoader, setLoader] = useState(0);

    useEffect(() => {
        async function get_detaile() {
            setLoader(0);
            var res_data = await post(GET_TABLE_BOOK_DETAIL, { booking_no: props.match.params.booking_no });
            console.log(res_data);
            if (res_data.status) {
                setBookingDetail(res_data.data.booking_detail);
            } else {
                toast.error(res_data.message);
                history.push("/");
            }
            setLoader(1);
        }
        get_detaile();
    }, [props.match.params.booking_no]);
    if (!isLoader) {
        return ('Loading...');
    }
    return (<>
        <UserNameHeader />
        <div class="container">
            <div class="order_his">
                <UserMenu />
                <div class="order_details_row">
                    <Link to="/functions-booking-history"><img src="/assets/images/back_order.svg" /></Link>
                    <span order_number><b>Booking No: #{BookingDetail.booking_no}</b></span>
                    {BookingDetail.status === 'Pending' && <span class="btn_panding">{BookingDetail.status}</span>}
                    {BookingDetail.status === 'Confirmed' && <span class="btn_confirmed">{BookingDetail.status}</span>}
                    {BookingDetail.status === 'Complete' && <span class="btn_complete">{BookingDetail.status}</span>}
                    {BookingDetail.status === 'Cancelled' && <span class="btn_canceld">{BookingDetail.status}</span>}
                </div>

                <div class="order_func_details">
                    <ul>
                        <li>
                            <span><b>Name:</b></span>
                            <span>{BookingDetail.first_name} </span>
                        </li>
                        <li>
                            <span><b>Email:</b></span>
                            <span>{BookingDetail.email}</span>
                        </li>
                        <li>
                            <span><b>Phone:</b></span>
                            <span>{BookingDetail.phone_number}</span>
                        </li>
                    </ul>

                    <ul>
                        <li>
                            <span><b>Preferred Date:</b></span>
                            <span>{BookingDetail.order_date}</span>
                        </li>
                        <li>
                            <span><b>Start Time:</b></span>
                            <span>{BookingDetail.time} - {BookingDetail.booking_date}</span>
                        </li>
                        <li>
                            <span><b>Number Of Guests:</b></span>
                            <span>{BookingDetail.number_of_people}</span>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <span><b>Type of Function:</b></span>
                            <span>{BookingDetail.function_type}</span>
                        </li>
                        <li>
                            <span><b>Food Selection:</b></span>
                            <span>{BookingDetail.food}</span>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <span><b>Additional Information:</b></span>
                            <span>{BookingDetail.note}</span>
                        </li>
                    </ul>

                    <div class="booking_info_wrap">
                        <div class="btnbox">
                            <div class="inbtncol ">
                                {BookingDetail.status === 'Pending' && <Link to={`/function-booking/${BookingDetail.booking_no}`} class="ondabtn blackbtn withborder">Modify Table</Link>}
                            </div>
                            {/* <div class="inbtncol">
                                <a href="#" class="ondabtn redbtn withborder">Cancel Table</a>
                            </div> */}
                            <div class="inbtncol">
                                <Link to="/function-booking" class="ondabtn greenbtn withborder">Book Again</Link>
                            </div>
                        </div>


                    </div>

                </div>
            </div>
        </div>

    </>)
}
